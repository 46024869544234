import React, { useEffect, useContext } from "react";
import { get } from "lodash";
import { graphql, Link, navigate } from "gatsby";
import PropTypes from "prop-types";

import Pages from "../../components/Pages";
import seoPage from "../../components/SEO/data";
import { ContentBlock, TextBlock } from "../../components/Blocks";
import { Image } from "../../components/Media/Image";
import { makeUrl, removeEmptyKeys, removePreloader } from "../../helpers";
import * as TargetBanner from "../../components/TargetBanner";
import { Share, BroadcastContext } from "../../widgets";
import { useThemeContext } from "../../components/Layout/ThemeContext";

export const query = graphql`
	query BradcastsQuery($pagePath: String!)  {
		hasura {
			v_broadcasts {
                src
                title_full
                title_short
                path
                enabled
				main_image
				main_image_preview
				slug
            }
			...Banners
			...PageSettings
		}
	}
`;

export default function Broadcasts({ data }) {
	const { theme } = useThemeContext();
	const { items } = useContext(BroadcastContext);

	const broadcasts = get(data, "hasura.v_broadcasts", []);
	const banners = get(data, "hasura.banners", []);
	const seo = get(data, "hasura.page_settings[0]", {});

	useEffect(() => {
		if (broadcasts && broadcasts.length && broadcasts.length === 1) {
			const slug = get(broadcasts, "[0].slug");

			navigate(makeUrl.broadcast({ slug }), { replace: true });
		}
	}, [broadcasts]);

	useEffect(() => {
		removePreloader();
	}, []);

	const url = "broadcasts";
	const bannerBlock = banners.filter(banner => get(banner, "params.enabled", false) && get(banner, "params.locations", "").match(/broadcasts/)).map(banner => {
		return React.createElement(TargetBanner[get(banner, "params.type", "")] || TargetBanner.BannerImg, {
			...banner,
		});
	});

	function getEnabled(item) {
		return items.some(({ slug, enabled }) => item.slug === slug && enabled);
	}

	return (
		<Pages entity={seo || get(seoPage, "broadcasts", {})} url={url}>
			{!!bannerBlock.length && <ContentBlock key={"banner"}>
				<div className={"banner__main-block d-noscript-none"}>{bannerBlock}</div>
			</ContentBlock>}
			<ContentBlock key={"main-content"}>
				<div className="container">
					<TextBlock title={"Онлайн-трансляции"}>
						<div className="container">
							<div className={"row"}>
								{
									broadcasts.map((broadcast, idx) =>
										<div key={`broadcasts-${idx}`} className={"col-lg-6 col-md-6 col-xs-12 mb-5 mt-3"}>
											<Link to={makeUrl.broadcast(broadcast)} style={{ display: "block" }}>
												<Image
													height={300}
													src={get(broadcast, "main_image.src", "")}
													style={{
														width: "100%",
														height: 300,
													}}
													responsive={[
														{
															src: get(broadcast, "main_image_preview.src", ""),
															media: "(max-width: 930px)",
														},
													]}
												/>
												<div style={removeEmptyKeys({
													display: "block",
													fontFamily: theme.fontFamily,
													fontSize: theme.fontSize.text,
													lineHeight: theme.lineHeight.text,
													color: theme.color.text,
												})} className="mt-3">
													{ get(broadcast, "title_full", get(broadcast, "title_short", "")) }
												</div>
												{ getEnabled(broadcast) ?
													<div className={"d-flex"}>
														<span className={"blink-circle"}>&bull;</span>
														<p className={"run-text d-flex"} style={{ alignItems: "center" }}><span>Идет трансляция</span></p>
													</div>
													: null
												}
											</Link>
										</div>,
									)
								}
							</div>
						</div>
					</TextBlock>
				</div>
			</ContentBlock>
			<div className={"container"}>
				<Share url={url} pageTitleShort={"Видеотрансляции"} />
			</div>
		</Pages>
	);
}

Broadcasts.propTypes = {
	data: PropTypes.object,
};

Broadcasts.defaultProps = {
	data: {},
};
